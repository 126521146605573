.tuile-bloc-recapitulatif, .tuile-personne-recherchee {
    width: 26%;
}

.tuile-detail-bloc-recapitulatif{
    width: 100%;
    flex-direction: column;
    word-break: break-word;
    text-align: left;
}

.tuile-detail-personne-recherchee{
    width: 100%;
    word-break: break-word;
    text-align: left;
}

.legende-formulaire{
    color: grey;
    font-style: italic;
    font-size: small;
}
