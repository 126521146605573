/*
Permet au contenu main d'occuper tout l'espace disponible pour ferrer le pied de page en bas
*/

body > div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
}